<script setup>
import { Link } from '@inertiajs/vue3';


const props = defineProps({
  size: {
    type: String,
    required: false,
    default: "medium",
  },
  theme: {
    type: String,
    required: false,
    default: "primary-contained",
  },
  iconPosition: {
    type: String,
    required: false,
    default: null,
  },
  text: {
    type: String,
    required: false,
    default: null,
  },
  type:{
    type:String,
    required: false,
    default: 'submit',
  },
  isLink:{
    type: Boolean,
    default: false
  },
  to:{
    type: String,
    default : null
  }
});

</script>
<template>
  <button v-if="isLink === false"
    v-bind="$attrs"
    :type="type"
    :class="[
      'items-center gap-2 rounded-lg px-3 py-2.5 font-bold leading-tight',
      {
        'text-[13px] px-3 py-1 font-bold  ': props.size === 'small',
        'text-sm px-3 py-2.5 font-bold ': props.size === 'medium',
        'text-base px-4 py-[11px] font-bold ': props.size === 'large',
		 
        'border border-transparent text-white bg-primary':
          props.theme === 'inherit-contained',
        'border border-secondary-10 text-primary bg-transparent':
          props.theme === 'inherit-outlined',
        'border border-transparent text-secondary-16 bg-transparent':
          props.theme === 'inherit-text',
        'border border-transparent text-secondary-16 bg-secondary-10':
          props.theme === 'inherit-soft',
			 'border border-main text-white bg-main':
          props.theme === 'main-contained',
			 'border bg-main text-primary bg-transparent':
          props.theme === 'main-outlined',
			 'border border-transparent text-main bg-transparent':
          props.theme === 'main-text',
			 'border border-main ':
          props.theme === 'main-soft',
			 'border border-transparent ':
          props.theme === 'primary-contained',
        'border border-primary text-primary bg-transparent':
          props.theme === 'primary-outlined',
        'border border-transparent text-primary ':
          props.theme === 'primary-text',
        'border border-transparent text-primary bg-secondary-12':
          props.theme === 'primary-soft',
        'border border-transparent text-white bg-system-default ':
          props.theme === 'system-contained',
        'border text-system-default border-[#FF56307A] ':
          props.theme === 'system-outlined',
        'border border-transparent text-system-default ':
        props.theme === 'system-text',
        'border border-transparent text-system-default bg-secondary-12':
          props.theme === 'system-soft',
        'flex':
          props.iconPosition,
      },
      '',
    ]"
  >

    <template v-if="props.iconPosition === 'left'">
        <slot name="leftIcon"/>
    </template>
    <slot/>
    {{ text }}
    <template v-if="props.iconPosition === 'right'">
        <slot name="rightIcon"/>
    </template>
  </button>

  <Link v-else
        v-bind="$attrs"
        :href="to"
        :class="[
        'items-center gap-2 rounded-lg px-3 py-2.5 font-bold leading-tight cursor-pointer',
            {
                'text-[13px] px-3 py-1 font-bold  ': props.size === 'small',
                'text-sm px-3 py-2.5 font-bold ': props.size === 'medium',
                'text-base px-4 py-[11px] font-bold ': props.size === 'large',
                'border border-transparent text-white bg-secondary-16':
                props.theme === 'inherit-contained',
                'border  border-secondary-10 text-secondary-16 bg-transparent':
                props.theme === 'inherit-outlined',
                'border border-transparent text-secondary-16 bg-transparent':
                props.theme === 'inherit-text',
                'border border-transparent text-secondary-16 bg-secondary-12':
                props.theme === 'inherit-soft',
                'border border-transparent text-white bg-primary':
                props.theme === 'primary-contained',
                'border border-primary text-primary bg-transparent':
                props.theme === 'primary-outlined',
                'border border-transparent text-primary ':
                props.theme === 'primary-text',
                'border border-transparent text-primary bg-secondary-12':
                props.theme === 'primary-soft',
                'border border-transparent text-white bg-system-default ':
                props.theme === 'system-contained',
                'border text-system-default border-[#FF56307A] bg-transparent':
                props.theme === 'system-outlined',
                'border border-transparent text-system-default ':
                props.theme === 'system-text',
                'border border-transparent text-system-default bg-secondary-12':
                props.theme === 'system-soft',
            },
            '',
        ]"
       >
         <template v-if="props.iconPosition === 'left'">
            <slot name="leftIcon"/>
        </template>
        <slot/>
        {{ text }}
        <template v-if="props.iconPosition === 'right'">
            <slot name="rightIcon"/>
        </template>
    </Link>

</template>
